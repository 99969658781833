import { useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import articlesSlice from './articlesSlice';
import parsingSlice from './parsingSlice';
import searchAreaSlice from './searchAreaSlice';
import authSlice from './authSlice/authSlice';
import objectsSlice from './objectsSlice/objectsSlice';
import hotelsSlice from './hotelsSlice';

import './articlesSlice/middleware/startArticlesListener';
import './searchAreaSlice/middleware/startSearchAreaListener';
import './authSlice/middleware/loginMiddleware';
import './objectsSlice/middleware/startHotelsListener';
import './parsingSlice/middleware/startParsingListener';

import { listenerMiddleware } from './listenerMiddleware';

const store = configureStore({
  reducer: {
    articles: articlesSlice,
    searchArea: searchAreaSlice,
    hotels: objectsSlice,
    auth: authSlice,
    parsing: parsingSlice,
    hotelsData: hotelsSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(listenerMiddleware.middleware)
});

// Infer the `RootState` and `AppDispatch` types from the store itself
// eslint-disable-next-line no-undef
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector = useSelector;

export default store;
