import * as yup from 'yup';
import _omitBy from 'lodash/omitBy';
import _isNil from 'lodash/isNil';
import _extend from 'lodash/extend';

export const enum HOTEL_FIELDS {
  NAME_FIELD_RU = 'name_translations.ru',
  SEO_TITLE_FIELD_RU = 'title_translations.ru',
  META_TAGS_FIELD = 'meta_tags'
}

// Для работы с вложенными полями через точку
export const validationSchema = () => {
  return yup.lazy((obj) => {
    return yup.object({
      name_translations: yup.object({
        ru: yup.string().required('Обязательное поле')
      }),
      title_translations: yup.object().shape({
        ru: yup.string()
      }),
      meta_tags: yup.array()
    });
  });
};

export const defaultValues = {
  name_translations: { ru: '' },
  title_translations: { ru: '' },
  meta_tags: []
};

export const extendDefaultValues = (values: object | null = {}) => {
  return _extend({}, defaultValues, _omitBy(values, _isNil));
};
