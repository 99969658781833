import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Text, Modal } from '@mantine/core';

import { useAppDispatch, useAppSelector } from 'store';
import Form from 'components/ui/Form';
import { STATUSES } from 'types';

import HotelForm from './components/HotelForm/HotelForm';
import { validationSchema } from './components/HotelForm/schema';
import { fetchHotelAction, updateHotelAction } from '../../../store/hotelsSlice/actions';
import { hotelSelector } from '../../../store/hotelsSlice/selectors';

function EditHotelForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [state, hotel] = useAppSelector(hotelSelector) as [STATUSES, any];
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchHotelAction(id));
  }, []);

  const handleSubmit = async (formData) => {
    const metaTags = formData.meta_tags?.map(tag => ({
      name: tag.name || 'Description',
      property: tag.property || 'name',
      content_translations: tag.content_translations || { ru: '', en: '' }
    })) || [];

    const preparedData = {
      name_translations: formData.name_translations || { ru: '' },
      title_translations: formData.title_translations || { ru: '' },
      meta_tags: metaTags
    };

    dispatch(
      updateHotelAction({
        data: preparedData,
        id
      })
    );

    navigate('/objects');
  };

  const handleReset = () => {
    navigate('/objects');
  };

  const handleError = (errors) => {
    console.log('handle error: ', errors);
  };

  if (!hotel) {
    return null;
  }

  return (
    <Modal
      size='lg'
      title={<Text fz='24px'>{t('Objects.Form.Edit')}</Text>}
      centered
      onClose={handleReset}
      withCloseButton={false}
      opened
    >
      <Form
        schema={validationSchema()}
        onSubmit={handleSubmit}
        onError={handleError}
        onReset={handleReset}
      >
        <HotelForm hotel={hotel} />
      </Form>
    </Modal>
  );
}

export default EditHotelForm;
