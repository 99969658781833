import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Flex, Button, Text } from '@mantine/core';

import { HOTEL_FIELDS } from './schema';
import InputForm from 'components/ui/InputForm';

import MetaTagsFormExtended from '../MetaTagsFormExtended';

type IProps = {
  hotel?: any;
};

function HotelForm({ hotel }: IProps) {
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  useEffect(() => {
    if (!hotel) {
      return;
    }
    setValue('name_translations.ru', hotel.name_translations?.ru || '');
    setValue('title_translations.ru', hotel.title_translations?.ru || '');
    setValue(HOTEL_FIELDS.META_TAGS_FIELD, hotel.meta_tags || []);
  }, [hotel, setValue]);

  return (
    <Flex direction='column' gap='lg'>
      <Flex direction='column' gap='xs'>
        <Text>{t('Common.General')}</Text>
        <InputForm
          name='name_translations.ru'
          placeholder={t('Common.Object.Name.Placeholder')}
          label={t('Common.Object.Name')}
          required
        />
      </Flex>

      <Flex direction='column' gap='xs'>
        <Text>{t('Common.Seo.Title')}</Text>
        <InputForm
          name='title_translations.ru'
          placeholder={t('Common.Title.Placeholder')}
          label={t('Common.Title')}
        />
        <Text mb={0} mt='lg'>
          {t('Common.MetaTags')}
        </Text>
        <MetaTagsFormExtended hotel={hotel} />
      </Flex>

      <Flex align='center' gap='md'>
        <Button type='submit' size='md' color='green' px='xl'>
          {t('Action.Save')}
        </Button>

        <Button type='reset' size='md' color='gray'>
          {t('Action.Cancel')}
        </Button>
      </Flex>
    </Flex>
  );
}

export default HotelForm;
