import React, { Fragment, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box, Flex, Button, ActionIcon, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconPlus, IconX } from '@tabler/icons-react';

import InputForm from 'components/ui/InputForm';
import MetaTagsSelect from 'components/forms/MetaTagsSelect';

const arrFieldName = 'meta_tags';
const TAG_FIELD = 'property';
const NAME_FIELD = 'name';
const CONTENT_TRANSLATIONS_FIELD = 'content_translations';

type MetaTagsFormExtendedProps = {
  hotel?: any;
};

function MetaTagsFormExtended({ hotel }: MetaTagsFormExtendedProps) {
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    name: arrFieldName
  });

  useEffect(() => {
    if (hotel?.meta_tags?.length) {
      if (fields.length > 0) {
        fields.forEach((_, index) => remove(index));
      }

      hotel.meta_tags.forEach((tag) => {
        append({
          name: tag.name,
          property: tag.property,
          content_translations: tag.content_translations || { ru: '', en: '' }
        });
      });
    }
  }, [hotel, append, remove]);

  const handleAddClick = (ev) => {
    ev.preventDefault();
    append({
      name: '',
      property: '',
      content_translations: { ru: '', en: '' }
    });
  };

  const handleRemoveClick = (index) => (ev) => {
    ev.preventDefault();
    remove(index);
  };

  return (
    <>
      {fields.map((item, index) => (
        <Fragment key={item.id}>
          <Flex gap='md' mb='lg'>
            <Flex flex='1' gap='sm' direction='column'>
              <Flex align='flex-start' gap='md'>
                <Box flex='1 1 50%'>
                  <MetaTagsSelect
                    name={`${arrFieldName}.${index}.${TAG_FIELD}`}
                    placeholder={t('Common.MetaTags.PropertyPlaceholder')}
                    label={t('Common.MetaTags.Property')}
                  />
                </Box>

                <Box flex='1 1 50%'>
                  <InputForm
                    name={`${arrFieldName}.${index}.${NAME_FIELD}`}
                    placeholder={t('Common.MetaTags.NamePlaceholder')}
                    label={t('Common.MetaTags.Name')}
                  />
                </Box>
              </Flex>

              <InputForm
                name={`${arrFieldName}.${index}.${CONTENT_TRANSLATIONS_FIELD}.ru`}
                placeholder={t('Common.MetaTags.ContentPlaceholder')}
                label={t('Common.MetaTags.Content')}
              />
            </Flex>
            <Box flex='none' pt='xl'>
              <ActionIcon onClick={handleRemoveClick(index)} size='sm' color='red'>
                <IconX size={16} />
              </ActionIcon>
            </Box>
          </Flex>
        </Fragment>
      ))}

      <Box ta='start'>
        <Button onClick={handleAddClick} size='sm' leftSection={<IconPlus size={18} />} variant='light'>
          Добавить пару
        </Button>
      </Box>
    </>
  );
}

export default MetaTagsFormExtended;
