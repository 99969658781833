import type { ILocaleString, IPagination, IResponseData } from './global';
import type { IRegionDocument } from './region';
import type { AuthorResponse } from './authors';

export interface IImage {
  encoded_url: string;
  url: string;
  _id: string;
}

export interface IMetaTag {
  content: string;
  name: string;
  property?: string;
}

export interface ICategory {
  name: string;
  tag: string;
}

export enum ARTICLE_FILTER_TYPE {
  BLOG = 'blog',
  DISEASE = 'disease',
  OBJECT = 'object',
  REGION = 'region'
}

export enum SHOW_TYPE {
  DEFAULT = 'default',
  LIST = 'list',
  REGION = 'region'
}

export interface IArticleLangContent {
  blocks: any[];
}

export interface IArticleContent {
  ru: IArticleLangContent;
  en?: IArticleLangContent;
}

export interface IArticle {
  category?: ICategory;
  reviewer?: AuthorResponse;
  meta_tags: IMetaTag[];
  seo_description: ILocaleString;
  coordinates?: number[];
  seo_title: ILocaleString;
  sub_title?: ILocaleString;
  title: ILocaleString;
  type: ARTICLE_FILTER_TYPE;
  content: IArticleContent;
  place?: IRegionDocument;
}

export interface IFilter {
  type: string;
}

export interface IArticleFilter {
  type: ARTICLE_FILTER_TYPE;
}

export interface IArticlesFetchData {
  filter: IArticleFilter;
  paginate: IPagination;
}

export interface IArticlesFetchParams {
  data: IArticlesFetchData;
}

export type IQueryParams = IArticlesFetchData;

export type IArticleResponseData<d, m> = IResponseData<d, m>;

export interface IArticlesMeta {
  total: number;
  query_params: IQueryParams;
}

// data for create `blog` post from modal from
export type ArticleRequest = Omit<IArticle, 'category' | 'reviewer' | 'type' | 'content'> & {
  category: Pick<ICategory, 'tag'>;
  reviewer: {
    id: AuthorResponse['id'];
  };
  type: `${ARTICLE_FILTER_TYPE}`;
};

// data from form `blog`
export type ArticleFormFields = {
  category: ICategory['tag'];
  meta_tags: IMetaTag[];
  reviewer: AuthorResponse['id'];
  seo_description_ru: string;
  seo_title_ru: string;
  title_ru: string;
  seo_description_en?: string;
  seo_title_en?: string;
  title_en?: string;
  type: `${ARTICLE_FILTER_TYPE}`;
};

// data for create `disease` post from modal from
export type DiseaseRequest = Omit<IArticle, 'category' | 'reviewer' | 'type' | 'content'> & {
  type: `${ARTICLE_FILTER_TYPE}`;
};

// data from form `disease`
export type DiseaseFormFields = {
  meta_tags: IMetaTag[];
  seo_description_ru: string;
  seo_title_ru: string;
  title_ru: string;
  sub_title_ru: string;
  seo_description_en?: string;
  seo_title_en?: string;
  title_en?: string;
  sub_title_en?: string;
  type: `${ARTICLE_FILTER_TYPE}`;
};

export type RegionRequest = Omit<IArticle, 'category' | 'reviewer' | 'type' | 'content'> & {
  place: IRegionDocument;
  type: `${ARTICLE_FILTER_TYPE}`;
};

// data from form `regions`
export type RegionsFormFields = DiseaseFormFields & {
  place: IRegionDocument;
};

export interface IArticleResponse extends IArticle {
  created_at: string;
  id: string;
  image?: IImage;
  place?: IRegionDocument;
  published: boolean;
  published_at?: string;
  slug: string;
  updated_at: string;
}

// пропсы для передачи в любую страницу с выводом записей
export type BlogCardsProps = {
  type: `${SHOW_TYPE}`;
  sitePath: string;
  data: IArticleResponse[];
  onEdit: (id: IArticleResponse['id']) => void;
  onEditForm: (id: IArticleResponse['id']) => void;
  onEditAdditional: (id: IArticleResponse['id']) => void;
  onPublish: (id: IArticleResponse['id'], checked: boolean) => void;
  onDelete: (blog: IArticleResponse) => void;
};
