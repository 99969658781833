import { createSlice } from '@reduxjs/toolkit';
import { STATUSES } from 'types';
import { fetchHotelAction, updateHotelAction } from './actions';

interface IHotelsState {
  state: STATUSES;
  error: string | null;
  hotel: any;
}

const initialState: IHotelsState = {
  state: STATUSES.IDLE,
  error: null,
  hotel: null
};

const hotelsSlice = createSlice({
  name: 'hotels',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHotelAction.pending, (state) => {
        state.state = STATUSES.PENDING;
        state.error = null;
      })
      .addCase(fetchHotelAction.fulfilled, (state, action) => {
        state.state = STATUSES.FULFILLED;
        state.hotel = action.payload;
      })
      .addCase(fetchHotelAction.rejected, (state, action) => {
        state.state = STATUSES.REJECTED;
        state.error = action.payload as string;
      })
      .addCase(updateHotelAction.pending, (state) => {
        state.state = STATUSES.PENDING;
        state.error = null;
      })
      .addCase(updateHotelAction.fulfilled, (state) => {
        state.state = STATUSES.FULFILLED;
      })
      .addCase(updateHotelAction.rejected, (state, action) => {
        state.state = STATUSES.REJECTED;
        state.error = action.payload as string;
      });
  }
});

export default hotelsSlice.reducer; 