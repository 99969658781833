import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { toastify } from 'utils/toastify';

import connection from 'connection/instance';

export const fetchHotelAction = createAsyncThunk('hotels/fetchHotel', async (id: string, { rejectWithValue }) => {
  try {
    const response = await connection.get(`/api/hotels/${id}`);
    return response.data.hotel;
  } catch (error) {
    if (error instanceof AxiosError) {
      return rejectWithValue(error.response?.data);
    }
    return rejectWithValue(error);
  }
});

export const updateHotelAction = createAsyncThunk(
  'hotels/updateHotel',
  async ({ id, data }: { id: string; data: any }, { rejectWithValue }) => {
    try {
      const response = await connection.put(`/api/hotels/${id}`, { data });
      toastify('success', 'Объект успешно обновлен');
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        toastify('error', 'Ошибка при обновлении объекта');
        return rejectWithValue(error.response?.data);
      }
      toastify('error', 'Ошибка при обновлении объекта');
      return rejectWithValue(error);
    }
  }
);
